import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./sideNav.scss";
import homeInactive from "../../assets/images/homeInactive.svg";
import homeActive from "../../assets/images/homeActive.png";
import ordersInactive from "../../assets/images/ordersInactive.svg";
import ordersActive from "../../assets/images/ordersActive.png";
import storeInactive from "../../assets/images/storeInactive.svg";
import storeActive from "../../assets/images/storeActive.png";
import catalogueInactive from "../../assets/images/catalogueInactive.svg";
import catalogueActive from "../../assets/images/catalogueActive.png";
import contactsInactive from "../../assets/images/contactsInactive.svg";
import contactsActive from "../../assets/images/contactsActive.png";
import financialsInactive from "../../assets/images/financialsInactive.svg";
import financialsActive from "../../assets/images/financialsActive.png";
import promotionsInactive from "../../assets/images/promotionsInactive.svg";
import promotionsActive from "../../assets/images/promotionsActive.png";
import helpInactive from "../../assets/images/helpInactive.svg";
import helpActive from "../../assets/images/helpActive.png";
import settingsInactive from "../../assets/images/settingsInactive.svg";
import settingsActive from "../../assets/images/settingsActive.png";
import masterDataActive from "../../assets/images/masterDataActive.png";
import masterDataInactive from "../../assets/images/mastetdataInActive.svg";
import { getUserRole } from "../../utils/common";
import haderLogo from "../../assets/images/shopezsidelogo.png";
import NoImage from "../../assets/images/man.png";

const navLinks = [
    { image: homeInactive, url: "/aboutStore", name: "About Store" },
    { image: homeInactive, url: "/addProduct", name: "Listings" },
    { image: homeInactive, url: "/financials", name: "Financials" },
    { image: homeInactive, url: "/plans", name: "Plans" },
    { image: homeInactive, url: "/review", name: "Review" },
];

const navLinksAfterOnboarding = [
    {
        image: homeInactive,
        url: "/home",
        name: "Dashboard",
        activeImage: homeActive
    },
    {
        image: storeInactive,
        url: "/stores",
        name: "Stores",
        activeImage: storeActive,
    },
    {
        image: catalogueInactive,
        url: "/catalogue",
        name: "Catalogue",
        activeImage: catalogueActive,
        submenus: [
            {
                image: homeInactive,
                url: "/catalogue/listing",
                name: "Listing",
                parent: "/catalogue",
            },
            // {
            //     image: homeInactive,
            //     url: "/catalogue/collection",
            //     name: "Collection",
            //     parent: "/catalogue",
            // },
        ],
    },
    {
        image: ordersInactive,
        url: "/orders",
        name: "Orders",
        activeImage: ordersActive,
    },
    {
        image: contactsInactive,
        url: "/contacts",
        name: "Contacts",
        activeImage: contactsActive,
        submenus: [
            {
                image: homeInactive,
                url: "/contacts/suppliers",
                name: "Suppliers"
            },
            {
                image: homeInactive,
                url: "/contacts/customers",
                name: "Customers"
            },
        ],
    },
    // {
    //     image: financialsInactive,
    //     url: "/financials",
    //     name: "Financials",
    //     activeImage: financialsActive,
    // },
    {
        image: financialsInactive,
        url: "/transaction",
        name: "Transaction",
        activeImage: financialsActive,
    },
    {
        image: promotionsInactive,
        url: "/promotions",
        name: "Promotions",
        activeImage: promotionsActive,
        submenus: [
            {
                image: homeInactive,
                url: "/promotions/banners",
                name: "Banners"
            },
            {
                image: homeInactive,
                url: "/promotions/deals",
                name: "Deals"
            },
            {
                image: homeInactive,
                url: "/promotions/discounts",
                name: "Discounts"
            },
        ],
    },
    {
        image: settingsInactive,
        url: "/settings",
        name: "Settings",
        activeImage: settingsActive,
        submenus: [
            {
                image: homeInactive,
                url: "/settings/generalSettings",
                name: "General",
            },
            {
                image: homeInactive,
                url: "/settings/faqs",
                name: "FAQs"
            },
            {
                image: homeInactive,
                url: "/settings/plans",
                name: "Plans"
            },
            {
                image: financialsInactive,
                url: "/settings/financials",
                name: "Financials",
            },
            {
                image: homeInactive,
                url: "/settings/shippingDelivery",
                name: "Shipping & Delivery",
            },
        ],
    },
    // {
    //     image: helpInactive,
    //     url: "/helpCenter",
    //     name: "Help center",
    //     activeImage: helpActive,
    // },
];

const navLinksAfterOnboardingAdmin = [
    {
        image: catalogueInactive,
        url: "/onlineenquiry",
        name: "Online Enquiry",
        activeImage: catalogueActive,
    },
    {
        image: storeInactive,
        url: "/stores",
        name: "Stores",
        activeImage: storeActive,
    },
    {
        image: storeInactive,
        url: "/brands",
        name: "Brands",
        activeImage: storeActive,
    },
    {
        image: ordersInactive,
        url: "/orders",
        name: "Orders",
        activeImage: ordersActive,
    },
    {
        image: ordersInactive,
        url: "/driver",
        name: "Driver",
        activeImage: ordersActive,
    },
    {
        image: ordersInactive,
        url: "/campaign",
        name: "Campaign",
        activeImage: ordersActive,
    },
    {
        image: ordersInactive,
        url: "/costparameters",
        name: "Cost Parameters",
        activeImage: ordersActive,
    },
    {
        image: masterDataInactive,
        url: "/masterdata",
        name: "Master Data",
        activeImage: masterDataActive,
        submenus: [
            {
                image: homeInactive,
                url: "/masterdata/role",
                name: "Role",
            },
            {
                image: homeInactive,
                url: "/masterdata/territories",
                name: "Territories",
            },
            {
                image: homeInactive,
                url: "/masterdata/privacy_policies",
                name: "Privacy Policies",
            },
            {
                image: homeInactive,
                url: "/masterdata/terms",
                name: "Terms of Use",
            },
        ],
    },
    {
        image: contactsInactive,
        url: "/appusers",
        name: "App Users",
        activeImage: contactsActive,
    },
];

function SideNav(props) {
    const [progress, setProgessbar] = useState();
    const [ACTIVE, setISACTIVE] = useState("/home");
    const [SUBMENUACTIVE, setSUBMENUACTIVE] = useState("");

    useEffect(() => {
        setProgessbar(window.location.pathname);
    }, [progress]);

    const subMenuHandle = (item, i, url) => {
        setSUBMENUACTIVE(item.url);
    };

    const menuHandle = (location, indexMain, item) => {
        let path = location.pathname.slice(1);
        let afterSplit = path.split("/");
        setISACTIVE(`/${afterSplit[0]}`);
        setSUBMENUACTIVE(location.pathname);

        for (let i = 0; i < navLinksAfterOnboarding.length; i++) {
            if (navLinksAfterOnboarding[i].url === location.pathname) {
                if (navLinksAfterOnboarding[i].submenus && navLinksAfterOnboarding[i].submenus.length > 0) {
                    subMenuHandle(navLinksAfterOnboarding[i].submenus[0]);
                }
            }
        }

        if (getUserRole() === "super-admin") {
            for (let i = 0; i < navLinksAfterOnboardingAdmin.length; i++) {
                if (navLinksAfterOnboardingAdmin[i].url === location.pathname) {
                    if (navLinksAfterOnboardingAdmin[i].submenus && navLinksAfterOnboardingAdmin[i].submenus.length > 0) {
                        subMenuHandle(navLinksAfterOnboardingAdmin[i].submenus[0]);
                    }
                }
            }
        }

        if (item !== undefined) {
            subMenuHandle(item[0], 0, item[0].url);
        }
    };

    const showSideNav = () => {
        if (getUserRole() === "super-admin") {
            return (
                <ul>
                    {navLinksAfterOnboardingAdmin.map(({ url, name, image, submenus, activeImage }, index) => (
                        <li>
                            <NavLink
                                key={name}
                                exact
                                to={url}
                                className={props.menu && ACTIVE === url ? "linkMenuActiveOnboard" : props.menu && ACTIVE !== url ? "linkMenuInActiveOnboard" : !props.menu && ACTIVE === url ? "link-activeOnboard" : "nav-link"}
                                isActive={(match, location) =>
                                    menuHandle(location, index, submenus)
                                }
                            >
                                <img
                                    src={ACTIVE === url ? activeImage : image}
                                    style={{
                                        height: 18,
                                        width: 18,
                                        justifySelf: "center",
                                        marginRight: "10px",
                                        marginLeft: "10px",
                                    }}
                                    alt=""
                                />
                                {name}
                            </NavLink>
                            {
                                <ul>
                                    {ACTIVE === url && !props.menu && submenus !== undefined && navLinksAfterOnboardingAdmin[index].submenus.map((item, i) => (
                                        <li>
                                            <NavLink
                                                key={i}
                                                exact
                                                to={item.url}
                                                isActive={(match, location) =>
                                                    console.log(location)
                                                }
                                                onClick={() => subMenuHandle(item, i, url)}
                                                className={SUBMENUACTIVE === item.url ? "submenu-link-active" : "submenu-nav-link"}
                                            >
                                                {item.name}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            }
                        </li>
                    ))}
                </ul>
            );
        } else if (!props.onBoardingStatus) {
            return (
                <ul className="onProcessNav">
                    {navLinks.map(({ url, name, image }) => (
                        <li>
                            <NavLink
                                key={name}
                                exact
                                to={() => url}
                                className={props.menu && ACTIVE === url ? "linkMenuActiveOnboard" : props.menu && ACTIVE !== url ? "linkMenuInActiveOnboard" : !props.menu && ACTIVE === url ? "link-activeOnboard" : "nav-link"}
                                isActive={(match, location) => setISACTIVE(location.pathname)}
                            >
                                {name}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            );
        } else {
            return (
                <ul>
                    {navLinksAfterOnboarding.map(({ url, name, image, submenus, activeImage }, index) => (
                        <li>
                            <NavLink
                                key={name}
                                exact
                                to={url}
                                className={props.menu && ACTIVE === url ? "linkMenuActiveOnboard" : props.menu && ACTIVE !== url ? "linkMenuInActiveOnboard" : !props.menu && ACTIVE === url ? "link-activeOnboard" : "nav-link"}
                                isActive={(match, location) =>
                                    menuHandle(location, index, submenus)
                                }
                            >
                                <img
                                    src={ACTIVE === url ? activeImage : image}
                                    style={{
                                        height: 18,
                                        width: 18,
                                        justifySelf: "center",
                                        marginRight: "10px",
                                        marginLeft: "10px",
                                    }}
                                    alt=""
                                />
                                {name}
                            </NavLink>
                            {
                                <ul>
                                    {ACTIVE === url && !props.menu && submenus !== undefined && navLinksAfterOnboarding[index].submenus.map((item, i) => (
                                        <li>
                                            <NavLink
                                                key={i}
                                                exact
                                                to={item.url}
                                                isActive={(match, location) =>
                                                    console.log(location)
                                                }
                                                onClick={() => subMenuHandle(item, i, url)}
                                                className={SUBMENUACTIVE === item.url ? "submenu-link-active" : "submenu-nav-link"}
                                            >
                                                {item.name}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            }
                        </li>
                    ))}
                </ul>
            );
        }
    };

    return (
        <div className="sideNavigationContainer sideNav">
            <div className={props.menu ? "menuOnboarding active" : "menuOnboarding"}>
                {getUserRole() === "super-admin" ? (
                    <div className="container">
                        <div className="d-flex justify-content-center">
                            <img className="sidenavLogo mt-3" src={haderLogo} alt="" />
                        </div>
                        <div className="d-flex justify-content-center">
                            <img src={NoImage} className="sidenavImg" alt="" />
                        </div>
                        <div className="sidenavName mb-4 d-flex justify-content-center">
                            John Doe
                        </div>
                    </div>
                ) : (
                    " "
                )}
                <ul>{showSideNav()}</ul>
            </div>
        </div>
    );
}

export default SideNav;
