import React, { useState } from 'react';
import './BussinessCred.scss';
import DefaultIamge from "../../../../../../../../../assets/images/ShopezDefult.svg"
import PDF from "../../../../../../../../../assets/images/pdfHolder.svg"

const BussinessCred = ({ data }) => {
    const checkFileExtension = (data) => {
        var fileExtension = data?.split(".").pop();
        return fileExtension === "pdf";
    };
    return (
        <div>
            <div className='BussinessBox mt-3'>
                <div className="d-flex pt-3 pl-5">

                    {/* <img 
                            alt=""
                            style={{width: "200px", height: "200px"}}
                            src={data?.business_creditionals?.[0]?.file ? data?.business_creditionals?.[0]?.file : DefaultIamge }
                        /> */}
                    {data?.business_creditionals && data?.business_creditionals.length > 0 && data?.business_creditionals.map((item, index) => {
                        return (
                            <>
                                {checkFileExtension(item?.file) ?
                                    <>
                                        <a href={item?.file} target="_blank" style={{ textDecoration: "none", color: "black", fontSize: "12px", wordBreak: "break-all" }}>
                                            <img src={PDF} style={{ width: "200px", height: "200px" }} alt="" />
                                            <div style={{ width: "180px", height: "200px" ,paddingLeft:"15px"}}>{item.file ? item.file.split('/').pop() : ""}</div>
                                        </a>

                                    </>

                                    :
                                    <>
                                        <a href={item?.file} target="_blank" style={{ textDecoration: "none", color: "black", fontSize: "12px", wordBreak: "break-all" }}>
                                            <img src={item?.file ? item?.file : DefaultIamge} style={{ width: "200px", height: "200px", paddingRight: "20px" }} alt="" />
                                            <div style={{ width: "180px", height: "200px" }}>{item.file ? item.file.split('/').pop() : ""}</div>
                                        </a>
                                    </>
                                }
                            </>
                        )
                    })
                        //    ? (
                        //      <a href={data?.business_creditionals?.[0]?.file} target="_blank">
                        //          <img src={PDF} style={{width:"200px",height:"200px"}} alt="" />
                        //      </a>
                        //  ) : (
                        // <img src={data?.business_creditionals?.[0]?.file ? data?.business_creditionals?.[0]?.file : DefaultIamge } style={{width:"200px",height:"200px"}} alt="" />
                        //  )
                    }
                </div>
            </div>
        </div>
    )
}

export default BussinessCred;
