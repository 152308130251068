import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { InputAdornment } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import TimeIcon from "../../../assets/images/time-icon.svg"
import moment from 'moment';
import "./CostParameter.scss";
import Button from "../../../components/button/button";
import { getMethod, putMethodWithToken } from '../../../utils/services/api';
import urls from "../../../utils/services/apiUrl.json"
import SpinnerLoader from "../../../components/loader/spinnerLoader";
import { toast } from 'react-toastify';


function CostParameter(props) {
    const [loading, setLoading] = useState(false);
    const [ParameterValue, setparameterValue] = useState([{
        commission_structure: [],
        costs_breakdown: [],
        fuel_surcharge_province: [],
        weight_surcharge: [],
        surge_pricing: []
    }])

    useEffect(() => {
        getCostParametersList()
    }, [])

    const getCostParametersList = async () => {
        setLoading(true);
        await getMethod(urls.getCostParameters).then((response) => {
            if (response.status === 200 || response.status === 201) {
                let array = [...ParameterValue];
                array[0]["commission_structure"] = [];
                array[0]["costs_breakdown"] = [];
                array[0]["fuel_surcharge_province"] = [];
                array[0]["weight_surcharge"] = [];
                array[0]["surge_pricing"] = [];
                if (response?.data?.response?.commission_structure?.length > 0) {
                    response?.data?.response?.commission_structure?.map((item, index) => {
                        array?.[0]?.["commission_structure"].push(item)
                    })
                    setparameterValue(array)
                }
                if (response?.data?.response?.costs_breakdown?.length > 0) {
                    response?.data?.response?.costs_breakdown?.map((item, index) => {
                        array?.[0]?.["costs_breakdown"].push(item)
                    })
                    setparameterValue(array)
                }
                if (response?.data?.response?.fuel_surcharge_province?.length > 0) {
                    response?.data?.response?.fuel_surcharge_province?.map((item, index) => {
                        array?.[0]?.["fuel_surcharge_province"].push(item)
                    })
                    setparameterValue(array)
                }
                if (response?.data?.response?.weight_surcharge?.length > 0) {
                    response?.data?.response?.weight_surcharge?.map((item, index) => {
                        array?.[0]?.["weight_surcharge"].push(item)
                    })
                    setparameterValue(array)
                }
                if (response?.data?.response?.surge_pricing?.length > 0) {
                    response?.data?.response?.surge_pricing?.map((item, index) => {
                        array?.[0]?.["surge_pricing"].push(item)
                    })
                    setparameterValue(array)
                }
                setLoading(false);
            }
            setLoading(false);
        })
    }


    const handlePriceChange = (event, data, index) => {
        const newValue = event?.target?.value;
        if (data?.name === "Base Charge" || data?.name === "Variable Charge") {
            let updatedArray = [...ParameterValue];
            if (updatedArray?.[0]?.["costs_breakdown"]?.[index]) {
                updatedArray[0]["costs_breakdown"][index].percentage = newValue;
                setparameterValue(updatedArray)
            }
        }
        if (data?.name === "Driver" || data?.name === "Shopez") {
            let updatedArray = [...ParameterValue];
            if (updatedArray?.[0]?.["commission_structure"]?.[index]) {
                updatedArray[0]["commission_structure"][index].percentage = newValue;
                setparameterValue(updatedArray)
            }
        }
    }

    const handlePriceFuelChange = (event, index) => {
        const newValue = event?.target?.value;
        let updatedFuelArray = [...ParameterValue];
        if (updatedFuelArray?.[0]?.["fuel_surcharge_province"]?.[index]) {
            updatedFuelArray[0]["fuel_surcharge_province"][index].fuel_surcharge = newValue;
            setparameterValue(updatedFuelArray)
        }
    }

    const handleWeightLowChange = (event, index) => {
        const newValue = event?.target?.value;
        let updatedFuelArray = [...ParameterValue];
        if (updatedFuelArray?.[0]?.["weight_surcharge"]?.[index]) {
            updatedFuelArray[0]["weight_surcharge"][index].low = newValue;
            setparameterValue(updatedFuelArray)
        }
    }

    const handleWeightChange = (event, index, type) => {
        const newValue = event?.target?.value;
        let updatedFuelArray = [...ParameterValue];

        if (type === 'weight_low') {
            if (updatedFuelArray?.[0]?.["weight_surcharge"]?.[index]) {
                updatedFuelArray[0]["weight_surcharge"][index].low = newValue;
            }
        } else if (type === 'weight_high') {
            if (updatedFuelArray?.[0]?.["weight_surcharge"]?.[index]) {
                updatedFuelArray[0]["weight_surcharge"][index].high = newValue;
            }
        } else if (type === 'weight_surcharge') {
            if (updatedFuelArray?.[0]?.["weight_surcharge"]?.[index]) {
                updatedFuelArray[0]["weight_surcharge"][index].weight_surcharge = newValue;
            }
        }
        setparameterValue(updatedFuelArray)
    }

    const handleSugerPrice = (event, index) => {
        const newValue = event?.target?.value;
        let updatedFuelArray = [...ParameterValue];
        if (updatedFuelArray?.[0]?.["surge_pricing"]?.[index]) {
            updatedFuelArray[0]["surge_pricing"][index].surge_pricing = newValue;
            setparameterValue(updatedFuelArray)
        }
    }
    const handleDateChange = (date, index, type) => {
        const listArray = [...ParameterValue]

        if (type === 'start_time') {
            if (listArray[0]["surge_pricing"]?.[index]) {
                listArray[0]["surge_pricing"][index].start_time = moment(date).format('HH:mm:ss A')
                setparameterValue(listArray);

            }
        } else if (type === "end_time") {
            if (listArray[0]["surge_pricing"]?.[index]) {
                listArray[0]["surge_pricing"][index].end_time = moment(date).format('HH:mm:ss A')
                setparameterValue(listArray);

            }
        }
    }

    const surgeList = () => {
        if (loading) {
            return <SpinnerLoader />;
        } else {
            return (
                <div className="">
                    <div className="row mt-4">
                        <div className="col-4 h5-lable-heading">Surge Pricing</div>
                        <div className="col-4 h5-lable-heading">Pickup Time</div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-2 h5-list-title f-600">Hours Category</div>
                        <div className="col-2 h5-list-title f-600">Surge Tiers</div>
                        <div className="col-3 h5-list-title f-600">Start Time</div>
                        <div className="col-3 h5-list-title f-600">End Time</div>
                        <div className="col-2 h5-list-title f-600">Surge Pricing</div>
                    </div>

                    {ParameterValue?.[0]?.surge_pricing && ParameterValue?.[0]?.surge_pricing?.length > 0 && ParameterValue?.[0]?.surge_pricing.map((item, index) => {
                        return (
                            <div className="row mt-3 pt-3 pb-3" style={{ background: "#ECECEC" }}>
                                <div className="col-2 d-flex" style={{ alignItems: "center" }}>{item?.hour_category}</div>
                                <div className="col-2 d-flex" style={{ alignItems: "center" }}>{item?.surge_tiers}</div>
                                <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            id="time-picker"
                                            inputVariant="outlined"
                                            value={item?.start_time ? moment(item.start_time, 'HH:mm:ss').isValid() ? moment(item.start_time, 'HH:mm:ss').toDate() : null : null}
                                            onChange={date => handleDateChange(date, index, 'start_time')}
                                            InputProps={{ disableUnderline: true }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                            keyboardIcon={<img src={TimeIcon} alt="Time Icon" />}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            id="time-picker"
                                            inputVariant="outlined"
                                            value={item?.end_time ? moment(item.end_time, 'HH:mm:ss').isValid() ? moment(item.end_time, 'HH:mm:ss').toDate() : null : null}
                                            onChange={date => handleDateChange(date, index, 'end_time')}
                                            InputProps={{ disableUnderline: true }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                            keyboardIcon={<img src={TimeIcon} alt="Time Icon" />}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                    <ValidatorForm>
                                        <div className="">
                                            <TextValidator
                                                name="surge_pricing"
                                                variant="outlined"
                                                onChange={(e) => handleSugerPrice(e, index)}
                                                value={item?.surge_pricing}
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                    endAdornment: (
                                                        <InputAdornment position="start">%</InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </ValidatorForm>
                                </div>
                            </div>
                        )
                    })}


                </div>

            )
        }
    }

    const fuelList = () => {
        if (loading) {
            return <SpinnerLoader />;
        } else {
            return (
                <div className="">
                    <div className="row mt-xl-4">
                        <div className="col-12 h5-lable-heading fuel-title">Fuel Surcharge</div>
                    </div>
                    <div className="row mt-3 fuel-list">
                        <div className="col-4 h5-list-title f-600">Province</div>
                        <div className="col-4 h5-list-title f-600">Acronym</div>
                        <div className="col-4 h5-list-title f-600">Fuel Surcharge</div>
                    </div>

                    {ParameterValue?.[0]?.fuel_surcharge_province && ParameterValue?.[0]?.fuel_surcharge_province?.length > 0 && ParameterValue?.[0]?.fuel_surcharge_province.map((item, index) => {
                        return (
                            <div className="row mt-3 pt-3 pb-3 ml-xl-3" style={{ background: "#ECECEC" }}>
                                <div className="col-4 d-flex" style={{ alignItems: "center" }}>{item?.state_name}</div>
                                <div className="col-4 d-flex" style={{ alignItems: "center" }}>{item?.stateAbbrevation}</div>
                                <div className="col-4 d-flex" style={{ alignItems: "center" }}>
                                    <ValidatorForm>
                                        <div className="">
                                            <TextValidator

                                                name="brand_name"
                                                variant="outlined"
                                                onChange={(e) => handlePriceFuelChange(e, index)}
                                                value={item?.fuel_surcharge}
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                    endAdornment: (
                                                        <InputAdornment position="start">%</InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </ValidatorForm>
                                </div>
                            </div>

                        )
                    })}
                </div>
            )

        }
    }

    const weightCategory = () => {
        if (loading) {
            return <SpinnerLoader />;
        } else {
            return (
                <div className="">
                    <div className="row mt-3">
                        <div className="col-2 h5-list-title f-600">Weight Category</div>
                        <div className="col-2 h5-list-title f-600">Surge Tiers</div>
                        <div className="col-3 h5-list-title f-600">Low</div>
                        <div className="col-3 h5-list-title f-600">High</div>
                        <div className="col-2 h5-list-title f-600">Surge Pricing</div>
                    </div>

                    {ParameterValue?.[0]?.weight_surcharge && ParameterValue?.[0]?.weight_surcharge.length > 0 && ParameterValue?.[0]?.weight_surcharge.map((item, index) => {
                        return (
                            <div className="row mt-3 pt-3 pb-3" style={{ background: "#ECECEC" }}>
                                <div className="col-2 d-flex" style={{ alignItems: "center" }}>{item?.weight_category}</div>
                                <div className="col-2 d-flex" style={{ alignItems: "center" }}>{item?.surge_tiers}</div>
                                <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                    <ValidatorForm>
                                        <div className="">
                                            <TextValidator
                                                variant="outlined"
                                                onChange={(e) => handleWeightChange(e, index, 'weight_low')}
                                                value={item?.low}
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                    endAdornment: (
                                                        <InputAdornment position="start">%</InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </ValidatorForm>
                                </div>
                                <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                    <ValidatorForm>
                                        <div className="">
                                            <TextValidator
                                                name="brand_name"
                                                variant="outlined"
                                                onChange={(e) => handleWeightChange(e, index, 'weight_high')}
                                                value={item?.high}
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                    endAdornment: (
                                                        <InputAdornment position="start">%</InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </ValidatorForm>
                                </div>
                                <div className="col-2 d-flex" style={{ alignItems: "center" }}>
                                    <ValidatorForm>
                                        <div className="">
                                            <TextValidator
                                                name="brand_name"
                                                variant="outlined"
                                                onChange={(e) => handleWeightChange(e, index, 'weight_surcharge')}
                                                value={item?.weight_surcharge}
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                    endAdornment: (
                                                        <InputAdornment position="start">%</InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </ValidatorForm>
                                </div>
                            </div>

                        )
                    })}
                </div>
            )

        }
    }

    const handleUpdateCostParameter = async () => {
        let body = {
            "commission_structure": ParameterValue?.[0]?.["commission_structure"],
            "costs_breakdown": ParameterValue?.[0]?.["costs_breakdown"],
            "fuel_surcharge_province": ParameterValue?.[0]?.["fuel_surcharge_province"],
            "weight_surcharge": ParameterValue?.[0]?.["weight_surcharge"],
            "surge_pricing": ParameterValue?.[0]?.["surge_pricing"]
        }
        await putMethodWithToken(urls.updateCostParameter, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.response)
                getCostParametersList()
            } else {
                toast.error(response?.data?.response)
            }
        })
    }

    return (
        <div className={props.menu ? "screenLeftMargin w-100" : "w-100 screenLeftMarginResponse"}>
            <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                <h1 className="heading-title col-6">Cost Parameters</h1>


                <div className="col-md-12 col-lg-12 col-xl-7 col-xxl-7 mt-3">
                    <div className="row">
                        <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="h4-label mt-2">Cost Breakdown</div>
                            <div className="row mt-3">
                                {ParameterValue?.[0]?.costs_breakdown.map((item, ind) => {
                                    return (
                                        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                            <div className="h6-label" style={{ color: "#000000" }}>{item?.name}</div>
                                            <ValidatorForm>
                                                <TextValidator
                                                    fullWidth
                                                    name="brand_name"
                                                    variant="outlined"
                                                    onChange={(e) => handlePriceChange(e, item, ind)}
                                                    value={item?.percentage}
                                                    className="mt-2"
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                        startAdornment: (
                                                            <InputAdornment position="start">$</InputAdornment>
                                                        ),
                                                    }}
                                                />

                                            </ValidatorForm>
                                        </div>

                                    )
                                })}

                            </div>

                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="h4-label mt-2">Commissions Structure</div>
                            <div className="row mt-3">
                                {ParameterValue?.[0]?.commission_structure.map((item, ind) => {
                                    return (
                                        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                            <div className="h6-label" style={{ color: "#000000" }}>{item?.name}</div>
                                            <ValidatorForm>
                                                <TextValidator
                                                    name="brand_name"
                                                    variant="outlined"
                                                    onChange={(e) => handlePriceChange(e, item, ind)}
                                                    value={item?.percentage}
                                                    className="mt-2"
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                        endAdornment: (
                                                            <InputAdornment position="start">%</InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </ValidatorForm>
                                        </div>

                                    )
                                })}
                            </div>
                        </div>

                        <div className="col-12">
                            {surgeList()}
                        </div>

                        <div className="col-12 mt-2">
                            {weightCategory()}
                        </div>
                    </div>
                </div>

                <div className="col-md-12 col-lg-12 col-xl-5 col-xxl-5">
                    <div className="row">
                        <div className="col-12 mt-5  d-flex justify-content-end custom-time-picker">
                            {/* <Button type="onSubmit" name="Reset Changes" className="btn btn-cancle mr-3" /> */}
                            <Button type="onSubmit" onClick={() => handleUpdateCostParameter()} name="Save" className="btn btn-primary" />
                        </div>

                        <div className="col-12">
                            {fuelList()}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default CostParameter
